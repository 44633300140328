import { Cookies } from "react-cookie";
import { ApolloClient, ApolloLink } from "@apollo/client";
import { setContext } from "@apollo/client/link/context";
import { InMemoryCache } from "@apollo/client/cache";
import { createLink } from "apollo-v3-absinthe-upload-link";
import { buildSentryErrorLink } from "apollo-sentry-helper";

import { TOKEN_KEY } from "constants/auth";

const uri = process?.env?.REACT_APP_BACKEND_URL;

const httpLink = createLink({
  uri,
});

const authLink = setContext((_, { headers }) => {
  const cookies = new Cookies();
  const token = cookies.get(TOKEN_KEY, { path: "/" });

  return {
    headers: {
      ...headers,
      authorization: token ? `Bearer ${token}` : null,
    },
  };
});

const sentryErrorLink = buildSentryErrorLink();

type JSONValue =
  | undefined
  | null
  | string
  | number
  | boolean
  | JSONValue[]
  | { [key: string]: JSONValue };
export const removeNulls = <T extends JSONValue>(obj: T): void => {
  if (typeof obj === "object") {
    if (Array.isArray(obj)) {
      obj.forEach(removeNulls);
    } else {
      for (const key in obj) {
        if (obj[key] === null) {
          delete obj[key];
        } else {
          removeNulls(obj[key]);
        }
      }
    }
  }
};

const link = ApolloLink.from([
  sentryErrorLink,
  authLink,
  httpLink,
  // mapNullLink,
]);

const apolloClient = new ApolloClient({
  link,
  cache: new InMemoryCache(),
});

export default apolloClient;
