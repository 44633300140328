import FunderCell from "components/Table/TableCell/CellValue/FunderCell";
import UpdateButtonCell from "components/Table/TableCell/CellValue/UpdateButtonCell";
import i18n from "translations/i18n";

import { ColumnContent } from "./grantFlowTableTitles";

export enum ReportCenterTableTitlesEnum {
  ReportDeadline = "reportDeadline",

  Funder = "funder",

  Program = "program",

  Urgency = "urgency",

  AmountAwarded = "amountAwarded",

  DateSubmitted = "dateSubmitted",

  Status = "status",
}

const reportCenterTableColumns: Record<
  ReportCenterTableTitlesEnum,
  ColumnContent
> = {
  [ReportCenterTableTitlesEnum.ReportDeadline]: {
    label: i18n.t("tables.columns_titles.report_deadline"),
    field: ReportCenterTableTitlesEnum.ReportDeadline,
  },
  [ReportCenterTableTitlesEnum.Funder]: {
    label: i18n.t("tables.columns_titles.funder"),
    field: ReportCenterTableTitlesEnum.Funder,
    component: FunderCell,
  },
  [ReportCenterTableTitlesEnum.AmountAwarded]: {
    label: i18n.t("tables.columns_titles.amount_awarded"),
    field: ReportCenterTableTitlesEnum.AmountAwarded,
  },
  [ReportCenterTableTitlesEnum.Program]: {
    label: i18n.t("tables.columns_titles.program"),
    field: ReportCenterTableTitlesEnum.Program,
  },
  [ReportCenterTableTitlesEnum.Urgency]: {
    label: i18n.t("tables.columns_titles.urgency"),
    field: ReportCenterTableTitlesEnum.Urgency,
  },
  [ReportCenterTableTitlesEnum.DateSubmitted]: {
    label: i18n.t("tables.columns_titles.date_submitted"),
    field: ReportCenterTableTitlesEnum.DateSubmitted,
  },
  [ReportCenterTableTitlesEnum.Status]: {
    label: i18n.t("tables.columns_titles.status"),
    field: ReportCenterTableTitlesEnum.Status,
    component: UpdateButtonCell,
  },
};

/**
 * Defines reportCenter table titles.
 */
export const reportCenterFormattedColumns = (
  Object.keys(reportCenterTableColumns) as ReportCenterTableTitlesEnum[]
).map((key) => ({
  label: reportCenterTableColumns[key].label as string,
  field: reportCenterTableColumns[key].field as string,
  component: reportCenterTableColumns[key].component,
}));
