import FundersList from "views/FundersList";
import ResetPassword from "views/ResetPassword";
import Home from "views/Home";
import Ulysses from "views/Ulysses";
import Dashboard from "views/Dashboard";
import GrantFlow from "views/GrantFlow";
import ReportCenter from "views/ReportCenter";
import Calendar from "views/Calendar";
import SignUp from "views/SignUp";
import Login from "views/Login";
import ForgotPassword from "views/ForgotPassword";
import { RouteProps as RDRP } from "react-router-dom";
import FunderProfile from "views/FunderProfile";
import FunderDocuments from "views/FunderProfile/Documents";
import LargestFunders from "views/LargestFunders";
import Deadlines from "views/Deadlines";
import GrantList from "views/GrantList";
import type { FilterApplicationStatusEnum } from "generated/graphql/v2/graphql";
import { MonthsEnum } from "constants/months";
import { Programs } from "views/Programs";
import React from "react";

// for future where we will force the link component to accepts paths only
declare const PathTag: unique symbol;
type Path<T extends string> = T & { [PathTag]: never };
const mkPath = <T extends string>(path: T): Path<T> => path as Path<T>;

export type RouteProps = RDRP & {
  path: string;
  requireAuthentication: boolean;
  showHeader: boolean;
  showFooter: boolean;
  component: React.FC;
  componentProps: Record<string, unknown>;
  // the type I want to define is impossible,
  // and the definition takes care of the typing anyway
  pathWithParams: unknown;
};

const defaultRouteProps = {
  path: "/" as const,
  exact: true,
  strict: false,
  requireAuthentication: true,
  showHeader: true,
  showFooter: false,
  componentProps: {},
  pathWithParams<T extends { path: string }>(
    this: T,
  ): T["path"] extends `${string}:${string}` ? string : Path<T["path"]> {
    return mkPath(this.path);
  },
} satisfies Partial<RouteProps>;

export const pages = {
  root: {
    ...defaultRouteProps,
    showHeader: false,
    showFooter: false,
    requireAuthentication: false,
    path: "/" as const,
    component: Home,
  },
  ulysses: {
    ...defaultRouteProps,
    showFooter: false,
    requireAuthentication: true,
    path: "/ulysses" as const,
    component: Ulysses,
  },
  dashboard: {
    ...defaultRouteProps,
    showFooter: false,
    requireAuthentication: true,
    path: "/dashboard" as const,
    component: Dashboard,
  },
  grantList: {
    ...defaultRouteProps,
    showFooter: false,
    requireAuthentication: true,
    path: "/grants" as const,
    pathWithParams: (search: {
      submissionYear?: string;
      grantStatus: FilterApplicationStatusEnum;
    }) => mkPath(`/grants?${new URLSearchParams(search).toString()}`),
    component: GrantList,
  },
  grantFlow: {
    ...defaultRouteProps,
    requireAuthentication: true,
    path: "/grant-flow" as const,
    pathWithParams(search: {
      submissionMonth?: MonthsEnum;
      submissionYear?: string;
      grantStatus: FilterApplicationStatusEnum;
    }) {
      return mkPath(`/grant-flow?${new URLSearchParams(search).toString()}`);
    },
    component: GrantFlow,
    showFooter: false,
  },
  deadlines: {
    ...defaultRouteProps,
    requireAuthentication: true,
    path: "/deadlines" as const,
    component: Deadlines,
    showFooter: false,
  },
  reports: {
    ...defaultRouteProps,
    requireAuthentication: true,
    path: "/report-center" as const,
    component: ReportCenter,
    showFooter: false,
  },
  fundersList: {
    ...defaultRouteProps,
    requireAuthentication: true,
    path: "/funders" as const,
    component: FundersList,
    showFooter: false,
  },
  largestFunders: {
    ...defaultRouteProps,
    requireAuthentication: true,
    path: "/largest-funders" as const,
    component: LargestFunders,
    showFooter: false,
  },
  calendar: {
    ...defaultRouteProps,
    requireAuthentication: true,
    path: "/calendar" as const,
    component: Calendar,
  },
  funderProfile: {
    ...defaultRouteProps,
    requireAuthentication: true,
    path: "/funder/:id" as const,
    pathWithParams: (id: number) => mkPath(`/funder/${id}`),
    component: FunderProfile,
    showHeader: true,
    showFooter: false,
  },
  funderDocuments: {
    ...defaultRouteProps,
    requireAuthentication: true,
    path: "/funder/:id/documents" as const,
    pathWithParams: (id: number, path = "/") =>
      mkPath(`/funder/${id}/documents?path=${path}`),
    component: FunderDocuments,
  },
  login: {
    ...defaultRouteProps,
    showHeader: false,
    showFooter: false,
    requireAuthentication: false,
    path: "/login" as const,
    component: Login,
  },
  signUp: {
    ...defaultRouteProps,
    requireAuthentication: false,
    path: "/sign-up" as const,
    component: SignUp,
    showHeader: false,
    showFooter: false,
  },
  forgotPassword: {
    ...defaultRouteProps,
    requireAuthentication: false,
    path: "/forgot-password" as const,
    component: ForgotPassword,
    showHeader: false,
    showFooter: false,
  },
  resetPassword: {
    ...defaultRouteProps,
    requireAuthentication: false,
    path: "/reset-password" as const,
    component: ResetPassword,
    showHeader: false,
    showFooter: false,
  },
  programs: {
    ...defaultRouteProps,
    requireAuthentication: true,
    path: "/programs" as const,
    component: Programs,
    showFooter: false,
  },
} satisfies {
  [pageName: string]: RouteProps;
};
