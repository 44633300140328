import React, { useEffect } from "react";
import { Flex } from "@chakra-ui/react";
import { useTranslation } from "react-i18next";

import { filteredGrantFlowFormattedColumns, TableTitlesEnum } from "constants/grantFlowTableTitles";
import Table from "components/Table";
import SearchFilter from "components/Filters/SearchFilter";
import StatusFilter from "components/Filters/StatusFilter";
import useFilter, { filters } from "hooks/useFilter";
import formatGrantFlowTableData from "utils/formatGrantFlowTableData";
import {
  grantFlowStatuses,
  grantFlowStatusSelectOptions,
} from "constants/statuses";
import TableHeader from "components/Table/TableHeader";
import DateFilter from "components/Filters/DateFilter";
import { useFiscalYear } from "hooks/useFiscalYear";
import fiscalYearRange from "utils/fiscalYearRange";
import { format, parseISO } from "date-fns";

import GrantFlowActions from "./GrantFlowActions";
import {
  formatISO,
  lastDayOfMonth,
  setDate,
  setMonth,
  setYear,
} from "date-fns";
import { graphql } from "generated/graphql/v2";
import { useQuery } from "@apollo/client";
import { FilterApplicationStatusEnum } from "generated/graphql";

const monthsToNum = {
  JANUARY: 1,
  FEBRUARY: 2,
  MARCH: 3,
  APRIL: 4,
  MAY: 5,
  JUNE: 6,
  JULY: 7,
  AUGUST: 8,
  SEPTEMBER: 9,
  OCTOBER: 10,
  NOVEMBER: 11,
  DECEMBER: 12,
};

const ListApplicationsQuery = graphql(`
  query ListApplicationDeadlines(
    $filters: FilterApplicationParams!
    $pagination: PaginationParams
  ) {
    listApplications(filters: $filters, pagination: $pagination) {
      id
      award {
        id
        amountAwarded
        receivementDate
      }
      grant {
        id
        frequency
        program {
          id
          name
        }
        funder {
          id
          name
          lastAward {
            id
            receivementDate
          }
        }
        responseAverage
      }
      status
      isSubmittedAsIneligible
      ineligibleReason
      deadline
      deadlineType
      declined
      declinedDetails {
        declinedDate
        declinedReason
      }
      submission {
        id
        submissionDate
        amountRequested
      }
      reports {
        id
        urgency
        deadline
      }
    }
  }
`);

const GrantFlow: React.FC = () => {
  let tableColumns = filteredGrantFlowFormattedColumns([]);

  const [t] = useTranslation();

  const [search] = useFilter(filters.search);
  const [grantStatus, setGrantStatus] = useFilter(filters.grantStatus);
  let [submissionMonth] = useFilter(filters.submissionMonth);
  const [submissionYear] = useFilter(filters.submissionYear);
  const { organizationId = 0, currentFiscalYearStart, currentFiscalYearEnd } = useFiscalYear();

  let filterDate = null;
  let startAt = null;
  let endAt = null;
  if (submissionMonth == 'YEAR' && currentFiscalYearStart != undefined && currentFiscalYearEnd != undefined) {
    startAt = currentFiscalYearStart;
    endAt = currentFiscalYearEnd;
  } else {
    if (submissionMonth == 'YEAR') {
      submissionMonth = 'JANUARY';
    }
    filterDate = setMonth(
      setYear(new Date(), Number(submissionYear)),
      monthsToNum[submissionMonth as keyof typeof monthsToNum] - 1,
    );
    startAt = formatISO(setDate(filterDate, 1), {
      representation: "date",
    });
    endAt = formatISO(lastDayOfMonth(filterDate), {
      representation: "date",
    });
  }

  const { data: tableData, loading: tableLoading, refetch: refresh } = useQuery(
    ListApplicationsQuery,
    {
      variables: {
        filters: {
          organizationId,
          search: (search as string) ?? undefined,
          status: (grantStatus as FilterApplicationStatusEnum) ?? undefined,
          startAt: startAt,
          endAt: endAt,
        },
        pagination: {
          orderBy: "deadline",
          sortingOrder: "ASC",
        },
      },
    }
  );


  const onFiscalYearChange = (year: number) => {
    let fy = currentFiscalYearStart;
    if (fy == undefined) {
      fy = (new Date()).toDateString();
    }
    const { start, end } = fiscalYearRange(
      parseISO(fy),
      year
    );

    const s = start.toDateString()
    if (s == 'Invalid Date') {
      console.log("fy", fy)
      console.log("year input", year)
      console.log("start", start)
      console.log("end", end)
      return false;
    } else {
      startAt = formatISO(start, {
        representation: "date",
      });
      endAt = formatISO(end, {
        representation: "date",
      });

      refresh({
        filters: {
          organizationId,
          search: (search as string) ?? undefined,
          status: (grantStatus as FilterApplicationStatusEnum) ?? undefined,
          startAt: startAt,
          endAt: endAt,
        },
        pagination: {
          orderBy: "deadline",
          sortingOrder: "ASC",
        }
      });
    }
  }

  if(grantStatus == "DECLINED"){
    tableColumns = filteredGrantFlowFormattedColumns([TableTitlesEnum.AmountAwarded, TableTitlesEnum.DateReceived]);
  }else{
    tableColumns = filteredGrantFlowFormattedColumns([TableTitlesEnum.DeclinedDate]);
  }
  // https://github.com/Grantflow2/grantflow/issues/86#issuecomment-2119103592
  // only show amount awarded column on applications sent, applications awarded, applications pending. 
  // We do not need to show declined date on those tables.
  if(grantStatus === FilterApplicationStatusEnum.Submitted ||
    grantStatus === FilterApplicationStatusEnum.Awarded ||
    grantStatus === FilterApplicationStatusEnum.Pending
  ){
    //remove declined date 
    tableColumns = tableColumns.filter((f) => f.field !== TableTitlesEnum.DeclinedDate);
  }else{
    //remove amount awarded column and award date
    tableColumns = tableColumns.filter((f) => 
      f.field !== TableTitlesEnum.AmountAwarded && 
      f.field !== TableTitlesEnum.DateReceived 
    );
  }

  return (
    <Table
      table={formatGrantFlowTableData(tableData ?? {})}
      tableColumns={tableColumns}
      isLoading={tableLoading}
    >
      <TableHeader
        title={t("tables.titles.grant_flow")}
        flexDirection={{ base: "column", md: "row" }}
      >
        
        <GrantFlowActions />
      </TableHeader>

      <Flex flexDir="row" marginBottom={8}>
        <SearchFilter width="full" /> 

        <StatusFilter
          marginLeft={4}
          currentStatus={grantStatus as string}
          setStatus={setGrantStatus}
          tableStatuses={grantFlowStatuses}
          statusOptions={grantFlowStatusSelectOptions}
        />
      </Flex>

      <DateFilter onFiscalYearChange={onFiscalYearChange} />
    </Table>
  );
};

export default GrantFlow;
