import { gql } from "@apollo/client";

export default gql`
  query GetFunder($id: Int!) {
    getFunder(id: $id) {
      id
      name
      keyContacts
      email
      phone
      type
      website
      howToApply
      notes
      isPaused
      location {
        id
        city
        complement
        state
        streetAddress
        zipcode
      }
      grants {
        applications {
          id
          status
          deadline
          isSubmittedAsIneligible
          ineligibleReason
          submission {
            id
            submissionDate
            amountRequested
          }
          declinedDetails {
            declinedReason
            declinedDate
          }
          award {
            id
            isPrecisionByYear
            receivementDate
            amountAwarded
          }
          grant {
            program {
              id
              name
            }
          }
        }
      }
      awards {
        id
        isPrecisionByYear
        receivementDate
        amountAwarded
        program {
          id
          name
        }
        grantApplication {
          id
          submission {
            id
            submissionDate
            amountRequested
          }
        }
      }
    }
  }
`;
