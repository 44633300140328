import useAdminImpersonationStore, {
  readOrganization,
} from "components/AdminImpersonation/store";
import { useCurrentUser } from "contexts/currentUser";
import { AdminImpersonationFragmentFragment } from "generated/graphql/v2/graphql";

export const useOrganizationId = () => {
  const [currentUserData] = useCurrentUser();
  const aiStore = useAdminImpersonationStore();

  return aiStore.getOrganizationId(currentUserData?.organization?.id ?? 0);
};

export const useOrganization = (): Omit<
  AdminImpersonationFragmentFragment,
  "$fragmentRefs"
> => {
  const [currentUserData] = useCurrentUser();
  const aiStore = useAdminImpersonationStore();

  const org = aiStore.organization ?? currentUserData?.organization;


  const orgObj = org
    ? readOrganization(org)
    : { id: 0, currentFiscalYear: new Date().getFullYear() };

  if (currentUserData?.role == "MANAGER" && orgObj.currentFiscalYear == null) {
    orgObj.currentFiscalYear = new Date().getFullYear();
  }
  return orgObj;
};
