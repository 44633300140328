import React, { useEffect, useState } from "react";
import { Flex, Grid, GridItem, GridItemProps, VStack } from "@chakra-ui/react";

import ComparisonByYear from "views/Dashboard/Charts/ComparisonByYear";
import SummaryCard from "views/Dashboard/SummaryCard";
import elementIds from "views/Dashboard/elementIds";

import DashboardHeader from "./Header";
import FundingsByType from "./Charts/FundingsByType";
import BulletChart from "./Charts/BulletChart";
import { graphql } from "generated/graphql/v2";
import { useOrganization } from "hooks/_new/useOrganization";
import { useQuery } from "@apollo/client";
import { SelectYear } from "components/_new/theme/inputs/SelectYear";
import Loading from "components/Loading";

const chartContainerCss: GridItemProps["css"] = {
  "@media print": {
    maxHeight: "500px",
    breakInside: "avoid",
    "*": { boxShadow: "none !important" },
  },
};

const DashboardQuery = graphql(`
  query DashboardQuery(
    $organizationId: Int!
    $currentFy: Int!
    $chartFys: [Int!]!
  ) {
    getOrganization(id: $organizationId) {
      ...SelectYearFragment
      awardsByYear(fiscalYears: $chartFys) {
        ...ComparisonChartFragment
        ...DashboardSummaryFragment
      }
      funderTypesCount(fiscalYear: $currentFy) {
        ...FunderTypesCountFragment
      }
      ...BulletChartFragment
    }
  }
`);

const PrintQuery = graphql(`
  query PrintQuery($organizationId: Int!) {
    listApplications(filters: { organizationId: $organizationId }) {
      ...DashboardApplicationsFragment
    }
  }
`);

const Dashboard: React.FC = () => {
  const org = useOrganization();
  const [chartFy, setChartFy] = useState(org.currentFiscalYear);

  const { data } = useQuery(DashboardQuery, {
    variables: {
      organizationId: org.id,
      currentFy: chartFy,
      chartFys: [chartFy - 2, chartFy - 1, chartFy],
    },
  });

  const { data: printData } = useQuery(PrintQuery, {
    variables: { organizationId: org.id },
  });


  // when the organisation changes, 
  //set the selected fiscal year to the current organisation's fiscal year
  useEffect(() => {
    if (!org) {
      return;
    }
    setChartFy(org.currentFiscalYear);
  }, [org]);

  if (!data) {
    return <Loading />;
  }

  const SelectYearEl = SelectYear({
    organization: data.getOrganization,
    value: chartFy,
    onChange: (year) => {
      console.log("years: ", year);
      setChartFy(year);
    }
  });


  return (
    <Flex flexDirection="column">
      <DashboardHeader
        SelectYear={SelectYearEl}
        dataReady={Boolean(printData)}
      />

      <VStack
        spacing={6}
        padding={8}
        bg="#f8f8f8"
        css={{
          "@media print": {
            backgroundColor: "#ffffff",
            padding: 0,
          },
        }}
      >
        <Grid gap={6} width="full" templateColumns={{ base: "repeat(2, 1fr)" }}>
          <GridItem colSpan={2} css={{ "@media print": { order: 1 } }}>
            <SummaryCard
              applications={printData?.listApplications ?? []}
              summary={data.getOrganization.awardsByYear[0]}
              fiscalYear={chartFy}
            />
          </GridItem>
          <GridItem
            id={`for-print-${elementIds.fundraisngTracker}`}
            colSpan={2}
            css={chartContainerCss}
          >
            <BulletChart chartData={data.getOrganization} />
          </GridItem>
          <GridItem
            id={`for-print-${elementIds.comparisonByYear}`}
            colSpan={{ base: 2, lg: 1 }}
            css={chartContainerCss}
          >
            <ComparisonByYear
              awardsByYear={data.getOrganization.awardsByYear}
              SelectYear={SelectYearEl}
            />
          </GridItem>
          <GridItem
            id={`for-print-${elementIds.fundingByType}`}
            colSpan={{ base: 2, lg: 1 }}
            css={chartContainerCss}
          >
            <FundingsByType
              funderTypes={data.getOrganization.funderTypesCount}
              SelectYear={SelectYearEl}
            />
          </GridItem>
        </Grid>
      </VStack>
    </Flex>
  );
};

export default Dashboard;
