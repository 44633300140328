import React from "react";
import { Stack, Skeleton } from "@chakra-ui/react";
import { Control, Controller, DeepMap, FieldError } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { Input } from "react-rainbow-components";

import getErrorMessage from "utils/getErrorMessage";
import MaskedInput from "components/FormComponents/MaskedInput";
import { PHONE_MASK, ZIPCODE_MASK } from "constants/masks";
import ExternalLinkInput from "components/CustomInputs/ExternalLinkInput";

import { UpdateFunderSchemaWSum } from "settings/yup/schemas/updateFunderSchema";
import { SelectFunderType } from "components/_new/theme/inputs/SelectFunderType";
import FieldTitle from "components/FormComponents/MaskedInput/FieldTitle";

const ContactInformationFields: React.FC<{
  control: Control<UpdateFunderSchemaWSum>;
  isEditing: boolean;
  keyContactsCount?: number;
  errors: DeepMap<UpdateFunderSchemaWSum, FieldError>;
  isLoaded?: boolean;
}> = ({ control, isEditing, errors, keyContactsCount, isLoaded = true }) => {
  const [t] = useTranslation();

  return (
    <form className="contact-information-form">
      <Skeleton isLoaded={isLoaded}>
        <Stack spacing={6} flexDirection="column">
          <Controller
            as={Input}
            control={control}
            name="name"
            readOnly={!isEditing}
            label={t("contact_information_card.name")}
            error={getErrorMessage("name", errors)}
            labelAlignment="left"
          />
          <Controller
            as={Input}
            control={control}
            name="keyContacts"
            readOnly={!isEditing}
            label={t("contact_information_card.key_contact", {
              count: keyContactsCount,
            })}
            error={getErrorMessage("keyContacts", errors)}
            labelAlignment="left"
          />
          <Controller
            as={Input}
            control={control}
            name="email"
            readOnly={!isEditing}
            label={t("contact_information_card.email_address")}
            error={getErrorMessage("email", errors)}
            labelAlignment="left"
          />
          <Controller
            as={MaskedInput}
            name="phone"
            type="text"
            control={control}
            mask={PHONE_MASK}
            readOnly={!isEditing}
            title={t("contact_information_card.phone_number")}
            errors={errors}
          />
          <Controller
            as={SelectFunderType}
            control={control}
            name="type"
            readOnly={!isEditing}
            error={getErrorMessage("type", errors)}
          />
          <Controller
            as={ExternalLinkInput}
            control={control}
            name="website"
            readOnly={!isEditing}
            label={t("contact_information_card.website")}
            error={getErrorMessage("website", errors)}
            labelAlignment="left"
          />
          <Controller
            as={Input}
            control={control}
            name="street_address"
            readOnly={!isEditing}
            label={t("location_information_card.street_address")}
            error={getErrorMessage("street_address", errors)}
            labelAlignment="left"
          />
          <Controller
            as={Input}
            control={control}
            name="city"
            readOnly={!isEditing}
            label={t("location_information_card.city")}
            error={getErrorMessage("city", errors)}
            labelAlignment="left"
          />
          <Controller
            as={Input}
            control={control}
            name="state"
            readOnly={!isEditing}
            label={t("location_information_card.state")}
            error={getErrorMessage("state", errors)}
            labelAlignment="left"
          />
          <Controller
            required
            name="zipcode"
            control={control}
            readOnly={!isEditing}
            labelAlignment="left"
            render={({ value, onChange }) => {
              if (!isEditing) {
                return (
                  <>
                    <FieldTitle htmlFor="zipcode">{t("location_information_card.zipcode")}</FieldTitle>

                    <span>{value.substring(0, 10)}</span>
                  </>
                )
              } else {
                value = value.substring(0, 10).replace(/\D/g, '');
                if (value.length > 5) {
                  const position = 5;
                  value = value.substring(0, position) + "-" + value.substring(position);
                }
                value = value.substring(0, 10);
                return (
                  <Input
                    value={value}
                    onChange={onChange}
                    error={errors.name}
                    label={t("location_information_card.zipcode")}
                    labelAlignment="left"
                    type="text"
                  />
                );
              }
            }}
            error={getErrorMessage("zipcode", errors)}
          />
        </Stack>
      </Skeleton>
    </form>
  );
};

export default ContactInformationFields;
