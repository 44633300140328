import React, { useEffect, useMemo } from "react";
import { Flex } from "@chakra-ui/react";
import { useTranslation } from "react-i18next";
import { format, parseISO } from "date-fns";

import {
  FilterApplicationStatusEnum,
  ListApplicationsQuery,
  useListApplicationsLazyQuery,
} from "generated/graphql";
import { grantFlowFormattedColumns, TableTitlesEnum } from "constants/grantFlowTableTitles";
import Table from "components/Table";
import SearchFilter from "components/Filters/SearchFilter";
import StatusFilter from "components/Filters/StatusFilter";
import useFilter, { filters } from "hooks/useFilter";
import formatGrantFlowTableData from "utils/formatGrantFlowTableData";
import { getGrantStatus } from "utils/getStatus";
import {
  AllApplicationsEnum,
  grantFlowStatuses,
  grantFlowStatusSelectOptions,
} from "constants/statuses";
import TableHeader from "components/Table/TableHeader";
import { useFiscalYear } from "hooks/useFiscalYear";
import fiscalYearRange from "utils/fiscalYearRange";
import maybeApply from "utils/maybeApply";

import GrantFlowActions from "../GrantFlow/GrantFlowActions";
import { ListApplicationDeadlinesQuery } from "generated/graphql/v2/graphql";

const TITLES: Record<string, string> = {
  [AllApplicationsEnum.AllApplications]: "tables.titles.grant_flow",
  [FilterApplicationStatusEnum.Submitted]: "tables.titles.applications_sent",
  [FilterApplicationStatusEnum.Awarded]: "tables.titles.grants_awarded",
  [FilterApplicationStatusEnum.Pending]: "tables.titles.grants_pending",
};

const GrantList: React.FC = () => {
  

  const [t] = useTranslation();

  const [search] = useFilter(filters.search);
  const [grantStatus, setGrantStatus] = useFilter(filters.grantStatus);
  const [submissionYear] = useFilter(filters.submissionYear);

  const { currentFiscalYearStart, organizationId } = useFiscalYear();

  const [listApplications, { data: tableData, loading: tableLoading }] =
    useListApplicationsLazyQuery();

  const [startAt, endAt] = useMemo(
    () =>
      maybeApply(currentFiscalYearStart, (year) => {
        const { start, end } = fiscalYearRange(
          parseISO(year),
          submissionYear as number,
        );
        return [format(start, "yyyy-MM-dd"), format(end, "yyyy-MM-dd")];
      }) || [null, null],
    [currentFiscalYearStart, submissionYear],
  );

  useEffect(() => {
    if (!organizationId || !startAt || !endAt) {
      return;
    }

    listApplications({
      variables: {
        filters: {
          organizationId,
          search: search as string,
          status: getGrantStatus(grantStatus as FilterApplicationStatusEnum),
          startAt,
          endAt,
        },
      },
    });
  }, [listApplications, organizationId, grantStatus, startAt, search, endAt]);

  const rawTableData = (tableData ?? {}) as ListApplicationsQuery;

  const formattedTableData = formatGrantFlowTableData(
    rawTableData as ListApplicationDeadlinesQuery,
  );

  const sortKey =
    grantStatus === FilterApplicationStatusEnum.Awarded
      ? "dateReceived"
      : "applicationDeadline";
  const sortedTableData = formattedTableData.sort(
    (a, b) =>
      new Date(a[sortKey] as string).getTime() -
      new Date(b[sortKey] as string).getTime(),
  );

  let tableColumns = grantFlowFormattedColumns;
  // https://github.com/Grantflow2/grantflow/issues/86#issuecomment-2119103592
  // only show amount awarded column on applications sent, applications awarded, applications pending. 
  // We do not need to show declined date on those tables.
  if(grantStatus === FilterApplicationStatusEnum.Submitted ||
    grantStatus === FilterApplicationStatusEnum.Awarded ||
    grantStatus === FilterApplicationStatusEnum.Pending
  ){
    //remove declined date 
    tableColumns = tableColumns.filter((f) => f.field !== TableTitlesEnum.DeclinedDate);
  }else{
    //remove amount awarded column and award date
    tableColumns = tableColumns.filter((f) => 
      f.field !== TableTitlesEnum.AmountAwarded && 
      f.field !== TableTitlesEnum.DateReceived 
    );
  }

  return (
    <Table
      table={sortedTableData}
      tableColumns={tableColumns}
      isLoading={tableLoading}
    >
      <TableHeader
        title={t(TITLES[grantStatus as string])}
        flexDirection={{ base: "column", md: "row" }}
      >
        <GrantFlowActions />
      </TableHeader>

      <Flex flexDir="row" marginBottom={8}>
        <SearchFilter width="full" />

        <StatusFilter
          marginLeft={4}
          currentStatus={grantStatus as string}
          setStatus={setGrantStatus}
          tableStatuses={grantFlowStatuses}
          statusOptions={grantFlowStatusSelectOptions}
        />
      </Flex>
    </Table>
  );
};

export default GrantList;
