/* eslint-disable */
import * as types from "./graphql";
import type { TypedDocumentNode as DocumentNode } from "@graphql-typed-document-node/core";

/**
 * Map of all GraphQL operations in the project.
 *
 * This map has several performance disadvantages:
 * 1. It is not tree-shakeable, so it will include all operations in the project.
 * 2. It is not minifiable, so the string of a GraphQL query will be multiple times inside the bundle.
 * 3. It does not support dead code elimination, so it will add unused operations.
 *
 * Therefore it is highly recommended to use the babel or swc plugin for production.
 */
const documents = {
  "\n  query ListImpersonableOrganizations {\n    listOrganizations {\n      id\n      name\n      ...AdminImpersonationFragment\n    }\n  }\n":
    types.ListImpersonableOrganizationsDocument,
  "\n  fragment AdminImpersonationFragment on Organization {\n    id\n    currentFiscalYear\n  }\n":
    types.AdminImpersonationFragmentFragmentDoc,
  "\n  fragment FunderContactInformationFragment on Funder {\n    id\n    name\n    email\n    phone\n    type\n    website\n    keyContacts\n  }\n":
    types.FunderContactInformationFragmentFragmentDoc,
  "\n  mutation UpdateFunderMutation($id: Int!, $params: UpdateFunderParams!) {\n    updateFunder(id: $id, params: $params) {\n      ...FunderContactInformationFragment\n    }\n  }\n":
    types.UpdateFunderMutationDocument,
  "\n  fragment FunderLocationInformationFragment on Funder {\n    location {\n      id\n      city\n      complement\n      state\n      streetAddress\n      zipcode\n    }\n  }\n":
    types.FunderLocationInformationFragmentFragmentDoc,
  "\n  fragment FunderHowToApplyFragment on Funder {\n    id\n    howToApply\n  }\n":
    types.FunderHowToApplyFragmentFragmentDoc,
  "\n  mutation FunderHowToApplyMutation($id: Int!, $howToApply: String!) {\n    updateFunder(id: $id, params: { howToApply: $howToApply }) {\n      id\n      howToApply\n    }\n  }\n":
    types.FunderHowToApplyMutationDocument,
  "\n  fragment FunderProfileNotesFragment on Funder {\n    id\n    notes\n  }\n":
    types.FunderProfileNotesFragmentFragmentDoc,
  "\n  mutation FunderProfileNotesMutation($id: Int!, $notes: String!) {\n    updateFunder(id: $id, params: { notes: $notes }) {\n      id\n      notes\n    }\n  }\n":
    types.FunderProfileNotesMutationDocument,
  "\n  mutation CreateFunder($params: CreateFunderParams!) {\n    createFunder(params: $params) {\n      id\n      name\n      type\n      email\n      phone\n      keyContacts\n      website\n      location {\n      city\n      complement\n      state\n      streetAddress\n      zipcode\n    }\n    }\n  }\n":
    types.CreateFunderDocument,
  "\n  query ListProgramAndFunderOptions($organizationId: Int!) {\n    listFunders(filters: { organizationId: $organizationId }) {\n      id\n      name\n    }\n\n    listPrograms(organizationId: $organizationId) {\n      id\n      name\n    }\n  }\n":
    types.ListProgramAndFunderOptionsDocument,
  "\n  mutation CreateGrant($params: CreateGrantParams!) {\n    createGrant(params: $params) {\n      id\n      funder {\n        organization {\n          id\n          __typename\n        }\n        __typename\n      }\n      __typename\n    }\n  }\n":
    types.CreateGrantDocument,
  "\n  fragment ListProgramsFragment on Program {\n    id\n    name\n    yearlyGoal(fiscalYear: $fy)\n    achievedAmount(fiscalYear: $fy)\n    ...EditProgramGoalFragment\n  }\n":
    types.ListProgramsFragmentFragmentDoc,
  "\n  fragment SelectYearFragment on Organization {\n    firstFiscalYear\n    currentFiscalYear\n  }\n":
    types.SelectYearFragmentFragmentDoc,
  "\n  query GetCurrentUserQuery {\n    currentUser {\n      id\n      role\n      authorized\n      organization {\n        id\n        ...AdminImpersonationFragment\n      }\n    }\n  }\n":
    types.GetCurrentUserQueryDocument,
  "\n  mutation CreateProgram($params: CreateProgramParams!) {\n    createProgram(params: $params) {\n      id\n    }\n  }\n":
    types.CreateProgramDocument,
  "\n  fragment EditProgramGoalFragment on Program {\n    id\n    yearlyGoal(fiscalYear: $fy)\n  }\n":
    types.EditProgramGoalFragmentFragmentDoc,
  "\n  mutation EditProgramGoalMutation(\n    $programId: Int!\n    $year: Int!\n    $goal: Decimal!\n    $singleYear: Boolean\n  ) {\n    updateProgramYearlyGoal(\n      id: $programId\n      params: { goal: $goal, year: $year, singleYear: $singleYear }\n    ) {\n      id\n    }\n  }\n":
    types.EditProgramGoalMutationDocument,
  "\n  fragment BulletChartFragment on Organization {\n    yearlyGoal(fiscalYear: $currentFy)\n    bcAwards: awardsByYear(fiscalYears: [$currentFy]) {\n      awarded\n    }\n  }\n":
    types.BulletChartFragmentFragmentDoc,
  "\n  fragment ComparisonChartFragment on AwardByYear {\n    awarded\n    year\n  }\n":
    types.ComparisonChartFragmentFragmentDoc,
  "\n  fragment FunderTypesCountFragment on FunderTypesCount {\n    foundation\n    corporate\n    government\n    other\n  }\n":
    types.FunderTypesCountFragmentFragmentDoc,
  "\n  fragment PrintDetailsFragment on Application {\n    id\n    grant {\n      program {\n        name\n      }\n      funder {\n        name\n      }\n    }\n    amountAwarded\n    amountRequested\n    fiscalDate\n  }\n":
    types.PrintDetailsFragmentFragmentDoc,
  "\n  fragment DashboardApplicationsFragment on Application {\n    ...PrintDetailsFragment\n    status\n  }\n":
    types.DashboardApplicationsFragmentFragmentDoc,
  "\n  fragment DashboardSummaryFragment on AwardByYear {\n    awarded\n    awardedCount\n    pending\n    pendingCount\n    requested\n    requestedCount\n  }\n":
    types.DashboardSummaryFragmentFragmentDoc,
  "\n  query DashboardQuery(\n    $organizationId: Int!\n    $currentFy: Int!\n    $chartFys: [Int!]!\n  ) {\n    getOrganization(id: $organizationId) {\n      ...SelectYearFragment\n      awardsByYear(fiscalYears: $chartFys) {\n        ...ComparisonChartFragment\n        ...DashboardSummaryFragment\n      }\n      funderTypesCount(fiscalYear: $currentFy) {\n        ...FunderTypesCountFragment\n      }\n      ...BulletChartFragment\n    }\n  }\n":
    types.DashboardQueryDocument,
  "\n  query PrintQuery($organizationId: Int!) {\n    listApplications(filters: { organizationId: $organizationId }) {\n      ...DashboardApplicationsFragment\n    }\n  }\n":
    types.PrintQueryDocument,
  "\n  mutation CreateFolderMutation($funderId: Int!, $folder: String!) {\n    createFolder(funderId: $funderId, folder: $folder) {\n      name\n    }\n  }\n":
    types.CreateFolderMutationDocument,
  "\n  fragment EditFileFragment on File {\n    id\n    name\n    folder\n  }\n":
    types.EditFileFragmentFragmentDoc,
  "\n  mutation EditFileMutation($id: Int!, $name: String, $folder: String) {\n    updateFile(id: $id, params: { name: $name, folder: $folder }) {\n      id\n    }\n  }\n":
    types.EditFileMutationDocument,
  "\n  query ListFolderOptions($funderId: Int!) {\n    listFolders(filters: { funderId: $funderId }) {\n      name\n    }\n  }\n":
    types.ListFolderOptionsDocument,
  "\n  mutation UploadDocumentMutation(\n    $funderId: Int!\n    $file: Upload!\n    $folder: String!\n  ) {\n    uploadFile(params: { funderId: $funderId, file: $file, folder: $folder }) {\n      id\n    }\n  }\n":
    types.UploadDocumentMutationDocument,
  "\n  fragment FileRowFragment on File {\n    id\n    name\n    insertedAt\n    updatedAt\n    signedUrl\n    ...EditFileFragment\n  }\n":
    types.FileRowFragmentFragmentDoc,
  "\n  mutation deleteFile($id: Int!) {\n    deleteFile(id: $id) {\n      id\n    }\n  }\n":
    types.DeleteFileDocument,
  "\n  fragment FolderRowFragment on Folder {\n    name\n  }\n":
    types.FolderRowFragmentFragmentDoc,
  "\n  mutation deleteFolder($folder: String!, $funderId: Int!) {\n    deleteFolder(folder: $folder, funderId: $funderId) {\n      id\n    }\n  }\n":
    types.DeleteFolderDocument,
  "\n  query getFunderFolder($funderId: Int!, $folder: String!, $search: String!) {\n    getFolder(funderId: $funderId, folder: $folder) {\n      name\n      children(search: $search) {\n        ... on Folder {\n          __typename\n          name\n          ...FolderRowFragment\n        }\n\n        ... on File {\n          __typename\n          id\n          ...FileRowFragment\n        }\n      }\n    }\n  }\n":
    types.GetFunderFolderDocument,
  "\n  fragment FunderNextGrantsFragment on Funder {\n    grants {\n      frequency\n      program {\n        id\n        name\n      }\n      applications {\n        id\n        deadline\n        status\n      }\n    }\n  }\n":
    types.FunderNextGrantsFragmentFragmentDoc,
  "\n  fragment FunderPreviousGrantsFragment on Funder {\n    id\n    name\n    grants {\n      program {\n        id\n        name\n      }\n      applications {\n        id\n        deadline\n        award {\n          amountAwarded\n          receivementDate\n          responseDays\n        }\n        declinedDetails {\n          declinedDate\n        }\n        submission {\n          submissionDate\n          amountRequested\n        }\n        status\n      }\n    }\n    totalAwardAmount\n  }\n":
    types.FunderPreviousGrantsFragmentFragmentDoc,
  "\n  query GetFunderProfile($id: Int!) {\n    getFunder(id: $id) {\n      id\n      name\n      isPaused\n      notes\n      howToApply\n      ...FunderProfileNotesFragment\n      ...FunderHowToApplyFragment\n      ...FunderContactInformationFragment\n      ...FunderPreviousGrantsFragment\n      ...FunderNextGrantsFragment\n      ...FunderLocationInformationFragment\n    }\n  }\n":
    types.GetFunderProfileDocument,
  "\n  query ListFundersQuery($organizationId: Int!, $search: String) {\n    listFunders(filters: { organizationId: $organizationId, search: $search }) {\n      id\n      name\n      email\n      type\n      website\n      totalAwardAmount\n    }\n  }\n":
    types.ListFundersQueryDocument,
  "\n  query ListApplicationDeadlines(\n    $filters: FilterApplicationParams!\n    $pagination: PaginationParams\n  ) {\n    listApplications(filters: $filters, pagination: $pagination) {\n      id\n      award {\n        id\n        amountAwarded\n        receivementDate\n      }\n      grant {\n        id\n        frequency\n        program {\n          id\n          name\n        }\n        funder {\n          id\n          name\n          lastAward {\n            id\n            receivementDate\n          }\n        }\n        responseAverage\n      }\n      status\n      isSubmittedAsIneligible\n      ineligibleReason\n      deadline\n      deadlineType\n      declined\n      declinedDetails {\n        declinedDate\n        declinedReason\n      }\n      submission {\n        id\n        submissionDate\n        amountRequested\n      }\n      reports {\n        id\n        urgency\n        deadline\n      }\n    }\n  }\n":
    types.ListApplicationDeadlinesDocument,
  "\n  query getLargestFunders(\n    $organizationId: Int!\n    $startAt: Date!\n    $endAt: Date!\n    $type: FunderTypeEnum\n  ) {\n    getOrganization(id: $organizationId) {\n      id\n      oldestAward {\n        id\n        receivementDate\n      }\n      programs {\n        id\n        name\n      }\n      largestFunders(startAt: $startAt, endAt: $endAt, type: $type) {\n        overall {\n          id\n          name\n          totalAwardAmount(startAt: $startAt, endAt: $endAt)\n        }\n        programsFunders {\n          funders {\n            funder {\n              id\n              name\n            }\n            awardedAmount\n          }\n          program {\n            id\n            name\n          }\n        }\n      }\n    }\n  }\n":
    types.GetLargestFundersDocument,
  "\n  query ListProgramsQuery($orgId: Int!, $fy: Int!) {\n    getOrganization(id: $orgId) {\n      currentFiscalYear\n      ...SelectYearFragment\n\n      programs {\n        ...ListProgramsFragment\n      }\n    }\n  }\n":
    types.ListProgramsQueryDocument,
};

/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 *
 *
 * @example
 * ```ts
 * const query = graphql(`query GetUser($id: ID!) { user(id: $id) { name } }`);
 * ```
 *
 * The query argument is unknown!
 * Please regenerate the types.
 */
export function graphql(source: string): unknown;

/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: "\n  query ListImpersonableOrganizations {\n    listOrganizations {\n      id\n      name\n      ...AdminImpersonationFragment\n    }\n  }\n",
): (typeof documents)["\n  query ListImpersonableOrganizations {\n    listOrganizations {\n      id\n      name\n      ...AdminImpersonationFragment\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: "\n  fragment AdminImpersonationFragment on Organization {\n    id\n    currentFiscalYear\n  }\n",
): (typeof documents)["\n  fragment AdminImpersonationFragment on Organization {\n    id\n    currentFiscalYear\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: "\n  fragment FunderContactInformationFragment on Funder {\n    id\n    name\n    email\n    phone\n    type\n    website\n    keyContacts\n  }\n",
): (typeof documents)["\n  fragment FunderContactInformationFragment on Funder {\n    id\n    name\n    email\n    phone\n    type\n    website\n    keyContacts\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: "\n  mutation UpdateFunderMutation($id: Int!, $params: UpdateFunderParams!) {\n    updateFunder(id: $id, params: $params) {\n      ...FunderContactInformationFragment\n    }\n  }\n",
): (typeof documents)["\n  mutation UpdateFunderMutation($id: Int!, $params: UpdateFunderParams!) {\n    updateFunder(id: $id, params: $params) {\n      ...FunderContactInformationFragment\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: "\n  fragment FunderLocationInformationFragment on Funder {\n    location {\n      id\n      city\n      complement\n      state\n      streetAddress\n      zipcode\n    }\n  }\n",
): (typeof documents)["\n  fragment FunderLocationInformationFragment on Funder {\n    location {\n      id\n      city\n      complement\n      state\n      streetAddress\n      zipcode\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: "\n  fragment FunderHowToApplyFragment on Funder {\n    id\n    howToApply\n  }\n",
): (typeof documents)["\n  fragment FunderHowToApplyFragment on Funder {\n    id\n    howToApply\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: "\n  mutation FunderHowToApplyMutation($id: Int!, $howToApply: String!) {\n    updateFunder(id: $id, params: { howToApply: $howToApply }) {\n      id\n      howToApply\n    }\n  }\n",
): (typeof documents)["\n  mutation FunderHowToApplyMutation($id: Int!, $howToApply: String!) {\n    updateFunder(id: $id, params: { howToApply: $howToApply }) {\n      id\n      howToApply\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: "\n  fragment FunderProfileNotesFragment on Funder {\n    id\n    notes\n  }\n",
): (typeof documents)["\n  fragment FunderProfileNotesFragment on Funder {\n    id\n    notes\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: "\n  mutation FunderProfileNotesMutation($id: Int!, $notes: String!) {\n    updateFunder(id: $id, params: { notes: $notes }) {\n      id\n      notes\n    }\n  }\n",
): (typeof documents)["\n  mutation FunderProfileNotesMutation($id: Int!, $notes: String!) {\n    updateFunder(id: $id, params: { notes: $notes }) {\n      id\n      notes\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: "\n  mutation CreateFunder($params: CreateFunderParams!) {\n    createFunder(params: $params) {\n      id\n      name\n      type\n      email\n      phone\n      keyContacts\n      website\n      location {\n      city\n      complement\n      state\n      streetAddress\n      zipcode\n    }\n    }\n  }\n",
): (typeof documents)["\n  mutation CreateFunder($params: CreateFunderParams!) {\n    createFunder(params: $params) {\n      id\n      name\n      type\n      email\n      phone\n      keyContacts\n      website\n      location {\n      city\n      complement\n      state\n      streetAddress\n      zipcode\n    }\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: "\n  query ListProgramAndFunderOptions($organizationId: Int!) {\n    listFunders(filters: { organizationId: $organizationId }) {\n      id\n      name\n    }\n\n    listPrograms(organizationId: $organizationId) {\n      id\n      name\n    }\n  }\n",
): (typeof documents)["\n  query ListProgramAndFunderOptions($organizationId: Int!) {\n    listFunders(filters: { organizationId: $organizationId }) {\n      id\n      name\n    }\n\n    listPrograms(organizationId: $organizationId) {\n      id\n      name\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: "\n  mutation CreateGrant($params: CreateGrantParams!) {\n    createGrant(params: $params) {\n      id\n      funder {\n        organization {\n          id\n          __typename\n        }\n        __typename\n      }\n      __typename\n    }\n  }\n",
): (typeof documents)["\n  mutation CreateGrant($params: CreateGrantParams!) {\n    createGrant(params: $params) {\n      id\n      funder {\n        organization {\n          id\n          __typename\n        }\n        __typename\n      }\n      __typename\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: "\n  fragment ListProgramsFragment on Program {\n    id\n    name\n    yearlyGoal(fiscalYear: $fy)\n    achievedAmount(fiscalYear: $fy)\n    ...EditProgramGoalFragment\n  }\n",
): (typeof documents)["\n  fragment ListProgramsFragment on Program {\n    id\n    name\n    yearlyGoal(fiscalYear: $fy)\n    achievedAmount(fiscalYear: $fy)\n    ...EditProgramGoalFragment\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: "\n  fragment SelectYearFragment on Organization {\n    firstFiscalYear\n    currentFiscalYear\n  }\n",
): (typeof documents)["\n  fragment SelectYearFragment on Organization {\n    firstFiscalYear\n    currentFiscalYear\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: "\n  query GetCurrentUserQuery {\n    currentUser {\n      id\n      role\n      authorized\n      organization {\n        id\n        ...AdminImpersonationFragment\n      }\n    }\n  }\n",
): (typeof documents)["\n  query GetCurrentUserQuery {\n    currentUser {\n      id\n      role\n      authorized\n      organization {\n        id\n        ...AdminImpersonationFragment\n      }\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: "\n  mutation CreateProgram($params: CreateProgramParams!) {\n    createProgram(params: $params) {\n      id\n    }\n  }\n",
): (typeof documents)["\n  mutation CreateProgram($params: CreateProgramParams!) {\n    createProgram(params: $params) {\n      id\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: "\n  fragment EditProgramGoalFragment on Program {\n    id\n    yearlyGoal(fiscalYear: $fy)\n  }\n",
): (typeof documents)["\n  fragment EditProgramGoalFragment on Program {\n    id\n    yearlyGoal(fiscalYear: $fy)\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: "\n  mutation EditProgramGoalMutation(\n    $programId: Int!\n    $year: Int!\n    $goal: Decimal!\n    $singleYear: Boolean\n  ) {\n    updateProgramYearlyGoal(\n      id: $programId\n      params: { goal: $goal, year: $year, singleYear: $singleYear }\n    ) {\n      id\n    }\n  }\n",
): (typeof documents)["\n  mutation EditProgramGoalMutation(\n    $programId: Int!\n    $year: Int!\n    $goal: Decimal!\n    $singleYear: Boolean\n  ) {\n    updateProgramYearlyGoal(\n      id: $programId\n      params: { goal: $goal, year: $year, singleYear: $singleYear }\n    ) {\n      id\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: "\n  fragment BulletChartFragment on Organization {\n    yearlyGoal(fiscalYear: $currentFy)\n    bcAwards: awardsByYear(fiscalYears: [$currentFy]) {\n      awarded\n    }\n  }\n",
): (typeof documents)["\n  fragment BulletChartFragment on Organization {\n    yearlyGoal(fiscalYear: $currentFy)\n    bcAwards: awardsByYear(fiscalYears: [$currentFy]) {\n      awarded\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: "\n  fragment ComparisonChartFragment on AwardByYear {\n    awarded\n    year\n  }\n",
): (typeof documents)["\n  fragment ComparisonChartFragment on AwardByYear {\n    awarded\n    year\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: "\n  fragment FunderTypesCountFragment on FunderTypesCount {\n    foundation\n    corporate\n    government\n    other\n  }\n",
): (typeof documents)["\n  fragment FunderTypesCountFragment on FunderTypesCount {\n    foundation\n    corporate\n    government\n    other\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: "\n  fragment PrintDetailsFragment on Application {\n    id\n    grant {\n      program {\n        name\n      }\n      funder {\n        name\n      }\n    }\n    amountAwarded\n    amountRequested\n    fiscalDate\n  }\n",
): (typeof documents)["\n  fragment PrintDetailsFragment on Application {\n    id\n    grant {\n      program {\n        name\n      }\n      funder {\n        name\n      }\n    }\n    amountAwarded\n    amountRequested\n    fiscalDate\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: "\n  fragment DashboardApplicationsFragment on Application {\n    ...PrintDetailsFragment\n    status\n  }\n",
): (typeof documents)["\n  fragment DashboardApplicationsFragment on Application {\n    ...PrintDetailsFragment\n    status\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: "\n  fragment DashboardSummaryFragment on AwardByYear {\n    awarded\n    awardedCount\n    pending\n    pendingCount\n    requested\n    requestedCount\n  }\n",
): (typeof documents)["\n  fragment DashboardSummaryFragment on AwardByYear {\n    awarded\n    awardedCount\n    pending\n    pendingCount\n    requested\n    requestedCount\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: "\n  query DashboardQuery(\n    $organizationId: Int!\n    $currentFy: Int!\n    $chartFys: [Int!]!\n  ) {\n    getOrganization(id: $organizationId) {\n      ...SelectYearFragment\n      awardsByYear(fiscalYears: $chartFys) {\n        ...ComparisonChartFragment\n        ...DashboardSummaryFragment\n      }\n      funderTypesCount(fiscalYear: $currentFy) {\n        ...FunderTypesCountFragment\n      }\n      ...BulletChartFragment\n    }\n  }\n",
): (typeof documents)["\n  query DashboardQuery(\n    $organizationId: Int!\n    $currentFy: Int!\n    $chartFys: [Int!]!\n  ) {\n    getOrganization(id: $organizationId) {\n      ...SelectYearFragment\n      awardsByYear(fiscalYears: $chartFys) {\n        ...ComparisonChartFragment\n        ...DashboardSummaryFragment\n      }\n      funderTypesCount(fiscalYear: $currentFy) {\n        ...FunderTypesCountFragment\n      }\n      ...BulletChartFragment\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: "\n  query PrintQuery($organizationId: Int!) {\n    listApplications(filters: { organizationId: $organizationId }) {\n      ...DashboardApplicationsFragment\n    }\n  }\n",
): (typeof documents)["\n  query PrintQuery($organizationId: Int!) {\n    listApplications(filters: { organizationId: $organizationId }) {\n      ...DashboardApplicationsFragment\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: "\n  mutation CreateFolderMutation($funderId: Int!, $folder: String!) {\n    createFolder(funderId: $funderId, folder: $folder) {\n      name\n    }\n  }\n",
): (typeof documents)["\n  mutation CreateFolderMutation($funderId: Int!, $folder: String!) {\n    createFolder(funderId: $funderId, folder: $folder) {\n      name\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: "\n  fragment EditFileFragment on File {\n    id\n    name\n    folder\n  }\n",
): (typeof documents)["\n  fragment EditFileFragment on File {\n    id\n    name\n    folder\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: "\n  mutation EditFileMutation($id: Int!, $name: String, $folder: String) {\n    updateFile(id: $id, params: { name: $name, folder: $folder }) {\n      id\n    }\n  }\n",
): (typeof documents)["\n  mutation EditFileMutation($id: Int!, $name: String, $folder: String) {\n    updateFile(id: $id, params: { name: $name, folder: $folder }) {\n      id\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: "\n  query ListFolderOptions($funderId: Int!) {\n    listFolders(filters: { funderId: $funderId }) {\n      name\n    }\n  }\n",
): (typeof documents)["\n  query ListFolderOptions($funderId: Int!) {\n    listFolders(filters: { funderId: $funderId }) {\n      name\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: "\n  mutation UploadDocumentMutation(\n    $funderId: Int!\n    $file: Upload!\n    $folder: String!\n  ) {\n    uploadFile(params: { funderId: $funderId, file: $file, folder: $folder }) {\n      id\n    }\n  }\n",
): (typeof documents)["\n  mutation UploadDocumentMutation(\n    $funderId: Int!\n    $file: Upload!\n    $folder: String!\n  ) {\n    uploadFile(params: { funderId: $funderId, file: $file, folder: $folder }) {\n      id\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: "\n  fragment FileRowFragment on File {\n    id\n    name\n    insertedAt\n    updatedAt\n    signedUrl\n    ...EditFileFragment\n  }\n",
): (typeof documents)["\n  fragment FileRowFragment on File {\n    id\n    name\n    insertedAt\n    updatedAt\n    signedUrl\n    ...EditFileFragment\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: "\n  mutation deleteFile($id: Int!) {\n    deleteFile(id: $id) {\n      id\n    }\n  }\n",
): (typeof documents)["\n  mutation deleteFile($id: Int!) {\n    deleteFile(id: $id) {\n      id\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: "\n  fragment FolderRowFragment on Folder {\n    name\n  }\n",
): (typeof documents)["\n  fragment FolderRowFragment on Folder {\n    name\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: "\n  mutation deleteFolder($folder: String!, $funderId: Int!) {\n    deleteFolder(folder: $folder, funderId: $funderId) {\n      id\n    }\n  }\n",
): (typeof documents)["\n  mutation deleteFolder($folder: String!, $funderId: Int!) {\n    deleteFolder(folder: $folder, funderId: $funderId) {\n      id\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: "\n  query getFunderFolder($funderId: Int!, $folder: String!, $search: String!) {\n    getFolder(funderId: $funderId, folder: $folder) {\n      name\n      children(search: $search) {\n        ... on Folder {\n          __typename\n          name\n          ...FolderRowFragment\n        }\n\n        ... on File {\n          __typename\n          id\n          ...FileRowFragment\n        }\n      }\n    }\n  }\n",
): (typeof documents)["\n  query getFunderFolder($funderId: Int!, $folder: String!, $search: String!) {\n    getFolder(funderId: $funderId, folder: $folder) {\n      name\n      children(search: $search) {\n        ... on Folder {\n          __typename\n          name\n          ...FolderRowFragment\n        }\n\n        ... on File {\n          __typename\n          id\n          ...FileRowFragment\n        }\n      }\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: "\n  fragment FunderNextGrantsFragment on Funder {\n    grants {\n      frequency\n      program {\n        id\n      name\n      }\n      applications {\n        id\n        deadline\n        status\n      }\n    }\n  }\n",
): (typeof documents)["\n  fragment FunderNextGrantsFragment on Funder {\n    grants {\n      frequency\n      program {\n        id\n        name\n      }\n      applications {\n        id\n        deadline\n        status\n      }\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: "\n  fragment FunderPreviousGrantsFragment on Funder {\n    id\n    name\n    grants {\n      program {\n        id\n        name\n      }\n      applications {\n        id\n        deadline\n        award {\n          amountAwarded\n          receivementDate\n          responseDays\n        }\n        declinedDetails {\n          declinedDate\n        }\n        submission {\n          submissionDate\n          amountRequested\n        }\n        status\n      }\n    }\n    totalAwardAmount\n  }\n",
): (typeof documents)["\n  fragment FunderPreviousGrantsFragment on Funder {\n    id\n    name\n    grants {\n      program {\n        id\n        name\n      }\n      applications {\n        id\n        deadline\n        award {\n          amountAwarded\n          receivementDate\n          responseDays\n        }\n        declinedDetails {\n          declinedDate\n        }\n        submission {\n          submissionDate\n          amountRequested\n        }\n        status\n      }\n    }\n    totalAwardAmount\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: "\n  query GetFunderProfile($id: Int!) {\n    getFunder(id: $id) {\n      id\n      name\n      isPaused\n      notes\n      howToApply\n      ...FunderProfileNotesFragment\n      ...FunderHowToApplyFragment\n      ...FunderContactInformationFragment\n      ...FunderPreviousGrantsFragment\n      ...FunderNextGrantsFragment\n      ...FunderLocationInformationFragment\n    }\n  }\n",
): (typeof documents)["\n  query GetFunderProfile($id: Int!) {\n    getFunder(id: $id) {\n      id\n      name\n      isPaused\n      notes\n      howToApply\n      ...FunderProfileNotesFragment\n      ...FunderHowToApplyFragment\n      ...FunderContactInformationFragment\n      ...FunderPreviousGrantsFragment\n      ...FunderNextGrantsFragment\n      ...FunderLocationInformationFragment\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: "\n  query ListFundersQuery($organizationId: Int!, $search: String) {\n    listFunders(filters: { organizationId: $organizationId, search: $search }) {\n      id\n      name\n      email\n      type\n      website\n      totalAwardAmount\n    }\n  }\n",
): (typeof documents)["\n  query ListFundersQuery($organizationId: Int!, $search: String) {\n    listFunders(filters: { organizationId: $organizationId, search: $search }) {\n      id\n      name\n      email\n      type\n      website\n      totalAwardAmount\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: "\n  query ListApplicationDeadlines(\n    $filters: FilterApplicationParams!\n    $pagination: PaginationParams\n  ) {\n    listApplications(filters: $filters, pagination: $pagination) {\n      id\n      award {\n        id\n        amountAwarded\n        receivementDate\n      }\n      grant {\n        id\n        frequency\n        program {\n          id\n          name\n        }\n        funder {\n          id\n          name\n          lastAward {\n            id\n            receivementDate\n          }\n        }\n        responseAverage\n      }\n      status\n      isSubmittedAsIneligible\n      ineligibleReason\n      deadline\n      deadlineType\n      declined\n      declinedDetails {\n        declinedDate\n        declinedReason\n      }\n      submission {\n        id\n        submissionDate\n        amountRequested\n      }\n      reports {\n        id\n        urgency\n        deadline\n      }\n    }\n  }\n",
): (typeof documents)["\n  query ListApplicationDeadlines(\n    $filters: FilterApplicationParams!\n    $pagination: PaginationParams\n  ) {\n    listApplications(filters: $filters, pagination: $pagination) {\n      id\n      award {\n        id\n        amountAwarded\n        receivementDate\n      }\n      grant {\n        id\n        frequency\n        program {\n          id\n          name\n        }\n        funder {\n          id\n          name\n          lastAward {\n            id\n            receivementDate\n          }\n        }\n        responseAverage\n      }\n      status\n      isSubmittedAsIneligible\n      ineligibleReason\n      deadline\n      deadlineType\n      declined\n      declinedDetails {\n        declinedDate\n        declinedReason\n      }\n      submission {\n        id\n        submissionDate\n        amountRequested\n      }\n      reports {\n        id\n        urgency\n        deadline\n      }\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: "\n  query getLargestFunders(\n    $organizationId: Int!\n    $startAt: Date!\n    $endAt: Date!\n    $type: FunderTypeEnum\n  ) {\n    getOrganization(id: $organizationId) {\n      id\n      oldestAward {\n        id\n        receivementDate\n      }\n      programs {\n        id\n        name\n      }\n      largestFunders(startAt: $startAt, endAt: $endAt, type: $type) {\n        overall {\n          id\n          name\n          totalAwardAmount(startAt: $startAt, endAt: $endAt)\n        }\n        programsFunders {\n          funders {\n            funder {\n              id\n              name\n            }\n            awardedAmount\n          }\n          program {\n            id\n            name\n          }\n        }\n      }\n    }\n  }\n",
): (typeof documents)["\n  query getLargestFunders(\n    $organizationId: Int!\n    $startAt: Date!\n    $endAt: Date!\n    $type: FunderTypeEnum\n  ) {\n    getOrganization(id: $organizationId) {\n      id\n      oldestAward {\n        id\n        receivementDate\n      }\n      programs {\n        id\n        name\n      }\n      largestFunders(startAt: $startAt, endAt: $endAt, type: $type) {\n        overall {\n          id\n          name\n          totalAwardAmount(startAt: $startAt, endAt: $endAt)\n        }\n        programsFunders {\n          funders {\n            funder {\n              id\n              name\n            }\n            awardedAmount\n          }\n          program {\n            id\n            name\n          }\n        }\n      }\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: "\n  query ListProgramsQuery($orgId: Int!, $fy: Int!) {\n    getOrganization(id: $orgId) {\n      currentFiscalYear\n      ...SelectYearFragment\n\n      programs {\n        ...ListProgramsFragment\n      }\n    }\n  }\n",
): (typeof documents)["\n  query ListProgramsQuery($orgId: Int!, $fy: Int!) {\n    getOrganization(id: $orgId) {\n      currentFiscalYear\n      ...SelectYearFragment\n\n      programs {\n        ...ListProgramsFragment\n      }\n    }\n  }\n"];

export function graphql(source: string) {
  return (documents as any)[source] ?? {};
}

export type DocumentType<TDocumentNode extends DocumentNode<any, any>> =
  TDocumentNode extends DocumentNode<infer TType, any> ? TType : never;
