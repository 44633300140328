import { useQuery } from "@apollo/client";
import Loading from "components/Loading";
import { ListPrograms } from "components/_new/objectViews/Programs/ListPrograms";
import { Header } from "components/_new/theme/Header";
import { SelectYear } from "components/_new/theme/inputs/SelectYear";
import { PageWrapper } from "components/_new/theme/PageWrapper";
import { graphql } from "generated/graphql/v2";
import { useOrganization } from "hooks/_new/useOrganization";
import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { Flex } from "@chakra-ui/react";
import { Button } from "components/_new/theme/Button";
import { useCreateProgramModal } from "modals/CreateProgram";

const ListProgramsQuery = graphql(`
  query ListProgramsQuery($orgId: Int!, $fy: Int!) {
    getOrganization(id: $orgId) {
      currentFiscalYear
      ...SelectYearFragment

      programs {
        ...ListProgramsFragment
      }
    }
  }
`);

export const Programs: React.FC = () => {
  const [t] = useTranslation();
  const { id: orgId, currentFiscalYear } = useOrganization();
  const openCreateNewProgramModal = useCreateProgramModal();

  const [fy, setFy] = useState<number>(currentFiscalYear);
  const { data } = useQuery(ListProgramsQuery, { variables: { orgId, fy } });

  // when the organisation changes, 
  //set the selected fiscal year to the current organisation's fiscal year
  useEffect(() => {
    if (!orgId) {
      return;
    }
    setFy(currentFiscalYear);
  }, [orgId]);

  if (!data?.getOrganization) {
    return <Loading />;
  }

  return (
    <PageWrapper>
      <Flex justifyContent="space-between" alignItems="center">
        <Header as="h1">{t("programs_page.title")}</Header>
        <Flex gap={8}>
          <SelectYear
            organization={data.getOrganization}
            value={fy}
            onChange={setFy}
          />
          <Button
            background="secondary.200"
            onClick={openCreateNewProgramModal}
          >
            {t("buttons.add_new_program")}
          </Button>
        </Flex>
      </Flex>
      <ListPrograms programs={data.getOrganization.programs} year={fy} />
    </PageWrapper>
  );
};
