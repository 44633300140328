import React from "react";
import { FormProvider, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { useTranslation } from "react-i18next";

import FormWizard from "components/FormWizard";
import createFunderSchema, {
  CreateFunderSchema,
} from "settings/yup/schemas/createFunderSchema";
import { createFunderRefetchQueries } from "graphql/mutations/createFunderMutation";
import { useToast } from "contexts/toast";
import useWizardStore from "components/Wizard/store";
import capitalizeStringFirstLetter from "utils/capitalizeStringFirstLetter";

import createFunderSteps from "./steps";
import { useOrganizationId } from "hooks/_new/useOrganization";
import { graphql } from "generated/graphql/v2";
import { useMutation } from "@apollo/client";
import { ModalComponentProps } from "contexts/modal/types";

const mutation = "\n  mutation CreateFunder($params: CreateFunderParams!) {\n    createFunder(params: $params) {\n      id\n      name\n      type\n      email\n      phone\n      keyContacts\n      website\n      location {\n      city\n      complement\n      state\n      streetAddress\n      zipcode\n    }\n    }\n  }\n"
const CreateFunderMutation = graphql(mutation);

// type
// email

const CreateFunderForm: React.FC<
  ModalComponentProps<{
    successCallback?: (funder: { id: number; name: string }) => void;
  }>
> = ({ hideModal, componentProps: { successCallback } }) => {
  const { addToast } = useToast();

  const [t] = useTranslation();

  const { setStepIndex } = useWizardStore((store) => ({
    setStepIndex: store.setStepIndex,
  }));

  const form = useForm<CreateFunderSchema>({
    resolver: yupResolver(createFunderSchema),
    mode: "onBlur",
    defaultValues: {
      name: "",
      type: undefined,
      email: "",
      phone: "",
      keyContacts: "",
      website: "",
      location: {
        city: "",
        complement: "",
        state: "",
        streetAddress: "",
        zipcode: "",
      }
    },
    shouldUnregister: false,
  });

  const organizationId = useOrganizationId();

  const [createFunder, { loading }] = useMutation(CreateFunderMutation, {
    refetchQueries: createFunderRefetchQueries,
    awaitRefetchQueries: true,
  });

  return (
    <FormProvider {...form}>
      <FormWizard
        steps={createFunderSteps}
        onSubmit={form.handleSubmit(async (values) => {
          try {

            const params: any = {
              name: values.name,
              type: values.type.value,
              organizationId: organizationId,
              email: values?.email ?? "",
              phone: values?.phone ?? "",
              keyContacts: values?.keyContacts ?? "",
              website: values?.website ?? "",
            };
            let city: string = values?.location?.city ?? "";
            city = city.trim();
            let state = values?.location?.state ?? "";
            state = state.trim();
            let streetAddress = values?.location?.streetAddress ?? "";
            streetAddress = streetAddress.trim();
            let zipcode = values?.location?.zipcode ?? "";
            zipcode = zipcode.trim();

            if (city.length > 0 || state.length > 0 || streetAddress.length > 0 || zipcode.length) {
              params["Location"] = {
                city: city,
                state: state,
                streetAddress: streetAddress,
                zipcode: zipcode
              };
            }

            const result = await createFunder({
              variables: {
                params: params
              },
            });

            const funder = result?.data?.createFunder;

            addToast({
              title: t("create_funder.success"),
              type: "success",
            });
            hideModal();
            setStepIndex(0);

            if (successCallback && funder?.id) {
              successCallback(funder);
            }
          } catch (error) {
            addToast({
              title: capitalizeStringFirstLetter((error as Error).message),
              type: "error",
            });
          }
        })}
        isLoading={loading}
      />
    </FormProvider>
  );
};

export default CreateFunderForm;
