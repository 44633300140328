import { Flex } from "@chakra-ui/react";
import React from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

import LogoIcon from "settings/theme/ChakraTheme/icons/LogoIcon";
import AdminImpersonation from "components/AdminImpersonation";

import HeaderLink from "./HeaderLink";
import HeaderMenu from "./HeaderMenu";
import HeaderExternalLink from "./HeaderExternalLink";
import { HeaderProps } from "./types";
import { pages } from "routes/pages";
import { useCurrentUser } from "contexts/currentUser";

const backendUrl = process?.env?.REACT_APP_BACKEND_URL;

const Header: React.FC<HeaderProps> = ({ showNavItems }) => {
  const [t] = useTranslation();
  const [user] = useCurrentUser();

  return (
    <nav className="header header-wrapper hide-on-print">
      <Link to={pages.dashboard.pathWithParams()}>
        <LogoIcon boxSize="80px" />
      </Link>

      <div className="links-wrapper">
        {showNavItems && (
          <>
            <HeaderLink
              to={pages.ulysses.pathWithParams()}
              title={t("header.ulysses")}
            />
            <HeaderLink
              to={pages.dashboard.pathWithParams()}
              title={t("header.dashboard")}
            />
            <HeaderLink
              to={pages.programs.pathWithParams()}
              title={t("header.programs")}
            />
            {/* forces a default status of did not apply */}
            <HeaderLink
              to={pages.grantFlow.pathWithParams({
                grantStatus: "NOT_APPLY",
              })}
              title={t("header.grant_flow")}
            />
            <HeaderLink
              to={pages.deadlines.pathWithParams()}
              title={t("header.deadlines")}
            />
            <HeaderLink
              to={pages.reports.pathWithParams()}
              title={t("header.report_center")}
            />
            <HeaderLink
              to={pages.fundersList.pathWithParams()}
              title={t("header.funders")}
            />
            <HeaderLink
              to={pages.largestFunders.pathWithParams()}
              title={t("header.largest_funders")}
            />
            {user?.role === "ADMIN" && (
              <HeaderExternalLink
                href={`${backendUrl}/../admin`}
                label={t("header.admin")}
              />
            )}
          </>
        )}
      </div>

      <Flex alignItems="center">
        <AdminImpersonation />

        <HeaderMenu />
      </Flex>
    </nav>
  );
};

export default Header;
