import React, { useEffect } from "react";

import useSession from "hooks/useSession";

import { CurrentUserProvider } from ".";
import { graphql } from "generated/graphql/v2";
import { GetCurrentUserQueryQuery } from "generated/graphql/v2/graphql";
import { useQuery, ApolloError } from "@apollo/client";

const GetCurrentUserQuery = graphql(`
  query GetCurrentUserQuery {
    currentUser {
      id
      role
      authorized
      organization {
        id
        ...AdminImpersonationFragment
      }
    }
  }
`);

export type CurrentUserContextPayload = [
  GetCurrentUserQueryQuery["currentUser"],
  {
    loading: boolean;
    error?: ApolloError;
  },
];

const CurrentUserContainer: React.FC = ({ children }) => {
  const [, , token] = useSession();
  const { data, loading, error, refetch } = useQuery(GetCurrentUserQuery);

  useEffect(() => {
    refetch();
  }, [token, refetch]);

  return (
    <CurrentUserProvider
      value={[
        data?.currentUser,
        {
          loading,
          error: error,
        },
      ]}
    >
      {children}
    </CurrentUserProvider>
  );
};

export default CurrentUserContainer;
